import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BannerOne from '../BannerOne/BannerOne';
import BannerTwo from '../BannerTwo/BannerTwo';

const MainCarrousel = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    draggable: true
  };

  return (
    <div className="MainCarrousel">
      <Slider {...sliderSettings}>
        <div>
          <BannerOne />
        </div>
        <div>
          <BannerTwo />
        </div>
      </Slider>
    </div>
  );
};

export default MainCarrousel;
