import React, { useState } from 'react';
import './BannerTwo.scss';

const BannerTwo = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [recordingLink, setRecordingLink] = useState('');

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    whatsapp: '',
    recordingLink: ''
  });

  const validateFields = () => {
    let isValid = true;
    let newErrors = {
      name: '',
      email: '',
      whatsapp: '',
      recordingLink: ''
    };

    if (!name) {
      newErrors.name = 'O nome é obrigatório.';
      isValid = false;
    }

    if (!email) {
      newErrors.email = 'O email é obrigatório.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Por favor, insira um email válido.';
      isValid = false;
    }

    if (!whatsapp) {
      newErrors.whatsapp = 'O WhatsApp é obrigatório.';
      isValid = false;
    } else if (!/^\+?[1-9]\d{1,14}$/.test(whatsapp)) {
      newErrors.whatsapp = 'Por favor, insira um número de WhatsApp válido.';
      isValid = false;
    }

    if (!recordingLink) {
      newErrors.recordingLink = 'O link da gravação é obrigatório.';
      isValid = false;
    } else if (!/^https?:\/\//.test(recordingLink)) {
      newErrors.recordingLink = 'O link da gravação deve começar com http:// ou https://';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      alert('Formulário enviado com sucesso!!');
      setName('');
      setEmail('');
      setWhatsapp('');
      setRecordingLink('');
    }, 2000);
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  }


  return (
    <>
      <div className='BannerTwo'>
        <div className="form-box-desk">
          <div className="form-container">
            <div className="form">
              <h3>Inscreva-se</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Nome</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Digite seu nome"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      handleInputChange(e, 'name');
                    }}
                  />
                  {errors.name && <small className="text-danger">{errors.name}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Digite seu email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleInputChange(e, 'email');
                    }}
                  />
                  {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="whatsapp">WhatsApp</label>
                  <input
                    type="text"
                    id="whatsapp"
                    className="form-control"
                    placeholder="Digite seu WhatsApp"
                    value={whatsapp}
                    onChange={(e) => {
                      setWhatsapp(e.target.value);
                      handleInputChange(e, 'whatsapp');
                    }}
                  />
                  {errors.whatsapp && <small className="text-danger">{errors.whatsapp}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="recordingLink">Link da Gravação</label>
                  <input
                    type="text"
                    id="recordingLink"
                    className="form-control"
                    placeholder="Cole aqui o link de sua gravação"
                    value={recordingLink}
                    onChange={(e) => {
                      setRecordingLink(e.target.value);
                      handleInputChange(e, 'recordingLink');
                    }}
                  />
                  {errors.recordingLink && <small className="text-danger">{errors.recordingLink}</small>}
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Carregando...' : 'Enviar'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="form-box-open">
        <div className="form-container">
          <div className="form">
            <h3>Inscreva-se</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Digite seu nome"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    handleInputChange(e, 'name');
                  }}
                />
                {errors.name && <small className="text-danger">{errors.name}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Digite seu email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleInputChange(e, 'email');
                  }}
                />
                {errors.email && <small className="text-danger">{errors.email}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="whatsapp">WhatsApp</label>
                <input
                  type="text"
                  id="whatsapp"
                  className="form-control"
                  placeholder="Digite seu WhatsApp"
                  value={whatsapp}
                  onChange={(e) => {
                    setWhatsapp(e.target.value);
                    handleInputChange(e, 'whatsapp');
                  }}
                />
                {errors.whatsapp && <small className="text-danger">{errors.whatsapp}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="recordingLink">Link da Gravação</label>
                <input
                  type="text"
                  id="recordingLink"
                  className="form-control"
                  placeholder="Cole aqui o link de sua gravação"
                  value={recordingLink}
                  onChange={(e) => {
                    setRecordingLink(e.target.value);
                    handleInputChange(e, 'recordingLink');
                  }}
                />
                {errors.recordingLink && <small className="text-danger">{errors.recordingLink}</small>}
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'Carregando...' : 'Enviar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerTwo
